var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-ctl",class:{
    'ui-ctl-date': _vm.type === 'date',
    'ui-ctl-time': _vm.type === 'time',
    'ui-ctl-datetime': _vm.type === 'datetime',
    'ui-ctl-after-icon': _vm.after === 'after',
    'ui-ctl-ext-after-icon': _vm.after === 'ext-after',
  }},[_c(_vm.isTagIcon,{tag:"component",staticClass:"ui-ctl-icon-calendar",class:{
      'ui-ctl-after': _vm.after === 'after',
      'ui-ctl-ext-after': _vm.after === 'ext-after',
    },on:{"click":function($event){return _vm.$emit('click')}}}),(_vm.slotType === 'select')?_c('select',{staticClass:"ui-ctl-element",on:{"change":function($event){return _vm.$emit('change', $event.target.value)}}},[_vm._t("default")],2):(_vm.slotType === 'input')?_c('input',{staticClass:"ui-ctl-element",attrs:{"type":_vm.type === 'datetime' ? 'datetime-local' : _vm.type},domProps:{"value":_vm.$slots.default[0].text},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"change":function($event){return _vm.$emit('change', $event.target.value)}}}):_c('div',{staticClass:"ui-ctl-element"},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }