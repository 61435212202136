var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-ctl ui-ctl-textbox ui-ctl-active ui-ctl-hover",class:{
    'ui-ctl-lg': _vm.size === 'lg',
    'ui-ctl-md': _vm.size === 'md',
    'ui-ctl-sm': _vm.size === 'sm',
    'ui-ctl-xs': _vm.size === 'xs',
    'ui-ctl-success': _vm.color === 'success',
    'ui-ctl-warning': _vm.color === 'warning',
    'ui-ctl-danger': _vm.color === 'danger',
    'ui-ctl-before-icon': _vm.beforeIcon && !_vm.beforeExt,
    'ui-ctl-ext-before-icon': _vm.beforeIcon && _vm.beforeExt,
    'ui-ctl-after-icon': _vm.afterIcon && !_vm.afterExt,
    'ui-ctl-ext-after-icon': _vm.afterIcon && _vm.afterExt,
    'ui-ctl-inline': _vm.inline,
    'ui-ctl-no-border': _vm.noBorder,
    'ui-ctl-underline': _vm.underline,
    'ui-ctl-no-padding': _vm.noPadding,
    'ui-ctl-round': _vm.round,
  },style:({
    'max-width': this.width || 'initial',
    'width': this.width || 'auto',
  })},[(_vm.beforeIcon)?_c(_vm.beforeButton ? 'button' : 'div',{tag:"component",class:{
       'ui-ctl-before': !_vm.beforeExt,
       'ui-ctl-ext-before': _vm.beforeExt,
       'ui-ctl-icon-search': _vm.beforeIcon === 'search',
       'ui-ctl-icon-calendar': _vm.beforeIcon === 'calendar',
       'ui-ctl-icon-dots': _vm.beforeIcon === 'dots',
       'ui-ctl-icon-phone': _vm.beforeIcon === 'phone',
       'ui-ctl-icon-mail': _vm.beforeIcon === 'mail',
       'ui-ctl-icon-clock': _vm.beforeIcon === 'clock',
       'ui-ctl-icon-angle': _vm.beforeIcon === 'angle',
       'ui-ctl-icon-clear': _vm.beforeIcon === 'clear',
       'ui-ctl-icon-loader': _vm.beforeIcon === 'loader',
     },on:{"click":function($event){return _vm.$emit('click-before')}}}):_vm._e(),(_vm.afterIcon)?_c(_vm.afterButton ? 'button' : 'div',{tag:"component",class:{
       'ui-ctl-after': !_vm.afterExt,
       'ui-ctl-ext-after': _vm.afterExt,
       'ui-ctl-icon-search': _vm.afterIcon === 'search',
       'ui-ctl-icon-calendar': _vm.afterIcon === 'calendar',
       'ui-ctl-icon-dots': _vm.afterIcon === 'dots',
       'ui-ctl-icon-phone': _vm.afterIcon === 'phone',
       'ui-ctl-icon-mail': _vm.afterIcon === 'mail',
       'ui-ctl-icon-clock': _vm.afterIcon === 'clock',
       'ui-ctl-icon-angle': _vm.afterIcon === 'angle',
       'ui-ctl-icon-clear': _vm.afterIcon === 'clear',
       'ui-ctl-icon-loader': _vm.afterIcon === 'loader',
     },on:{"click":function($event){return _vm.$emit('click-after')}}}):_vm._e(),(_vm.tag)?_c('div',{staticClass:"ui-ctl-tag",class:{
      'ui-ctl-tag-success': _vm.tagColor === 'success',
      'ui-ctl-tag-primary': _vm.tagColor === 'primary',
      'ui-ctl-tag-danger': _vm.tagColor === 'danger',
      'ui-ctl-tag-warning': _vm.tagColor === 'warning',
    }},[_vm._v(_vm._s(_vm.tag))]):_vm._e(),_c('input',{staticClass:"ui-ctl-element",attrs:{"type":"text","placeholder":_vm.placeholder,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"change":function($event){return _vm.$emit('change', $event.target.value)},"input":function($event){return _vm.$emit('input', $event.target.value)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }