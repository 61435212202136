var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-alert",class:{
    'ui-alert-md': _vm.size === 'md',
    'ui-alert-xs': _vm.size === 'xs',
    'ui-alert-default': _vm.color === 'default',
    'ui-alert-primary': _vm.color === 'primary',
    'ui-alert-success': _vm.color === 'success',
    'ui-alert-warning': _vm.color === 'warning',
    'ui-alert-danger': _vm.color === 'danger',
    'ui-alert-icon-warning': _vm.icon === 'warning',
    'ui-alert-icon-danger': _vm.icon === 'danger',
    'ui-alert-icon-info': _vm.icon === 'info',
    'ui-alert-text-center': _vm.center,
    'ui-alert-inline': _vm.inline,
  }},[_c('span',{staticClass:"ui-alert-message"},[(_vm.title)?_c('strong',[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_vm._t("default")],2),_c('span',{staticClass:"ui-alert-close-btn",on:{"click":function($event){return _vm.$emit('close')}}})])}
var staticRenderFns = []

export { render, staticRenderFns }